/**
 * Header Styles
 */

.mi-header {
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(0);
  height: 100vh;
  width: 300px;
  background: lighten($color-bg-body, 5);
  border-right: 1px solid $color-border;
  z-index: 10;
  transition: $transition;

  &-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    overflow-y: auto;
  }

  &-toggler {
    position: absolute;
    left: 100%;
    top: 20px;
    padding: 0;
    height: $input-button-height;
    width: 50px;
    text-align: center;
    font-size: 1.6rem;
    background: lighten($color-bg-body, 5);
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;

    &:focus {
      border-color: $color-border;
    }
  }

  &-image {
    padding: 20px 0;
    display: block;
    width: 100%;
    text-align: center;

    > a {
      height: 200px;
      width: 200px;
      border-radius: 1000px;
      border: 7px solid $color-border;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
    }

    &-certs {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: center;
      margin-top: 20px;
      padding-top: 20px;
      padding-left: 16px;
      padding-right: 16px;
      border-top: 1px solid $color-border;

      > a {
        height: 58px;
        width: 58px;
      }
    }
  }

  &-menu {
    width: 100%;
    padding: 15px 0px;
    margin-bottom: 0;
    margin-top: -150px;

    li {
      list-style: none;
      text-align: center;
      display: block;

      &:not(:last-child) {
        margin-bottom: 1px;
      }

      a {
        text-transform: uppercase;
        font-size: 0.94rem;
        letter-spacing: 1px;
        display: block;
        padding: 5px 0;
        color: $color-body;
        transition: $transition;
        font-weight: 600;
        position: relative;
        overflow: hidden;
        z-index: 1;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 100%;
          height: 100%;
          width: 0;
          background: rgba($color-theme, 0.2);
          visibility: hidden;
          transition: $transition;
          z-index: -1;
        }

        &:hover {
          color: $color-theme;

          &::before {
            width: 100%;
            visibility: visible;
          }
        }

        &.active {
          color: #ffffff;

          &::before {
            background: $color-theme;
            width: 100%;
            visibility: visible;
          }
        }
      }
    }
  }

  &-copyright {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    font-size: 1rem;
    padding: 15px 0;
    border-top: 1px solid $color-border;

    a {
      color: $color-body;
      transition: $transition;

      &:hover {
        color: $color-theme;
      }
    }
  }

  &.is-visible {
    transform: translateX(0);
  }

  @media #{$layout-laptop} {
    width: 260px;
  }

  @media #{$layout-notebook} {
    width: 260px;
    transform: translateX(-100%);

    &-toggler {
      display: flex;
    }

    &-image {
      a {
        height: 150px;
        width: 150px;
      }
    }

    &-menu {
      li {
        a {
          padding: 7px 0;
        }
      }
    }
  }

  @media #{$layout-tablet} {
    width: 250px;
    transform: translateX(-100%);

    &-toggler {
      display: flex;
    }

    &-image {
      a {
        height: 150px;
        width: 150px;
      }
    }

    &-menu {
      li {
        a {
          padding: 7px 0;
        }
      }
    }
  }

  @media #{$layout-mobile-lg} {
    width: 250px;
    transform: translateX(-100%);

    &-toggler {
      display: flex;
    }

    &-image {
      a {
        height: 150px;
        width: 150px;
      }
    }

    &-menu {
      li {
        a {
          padding: 7px 0;
        }
      }
    }
  }
}
