/*
 * Resume Styles
 */

.mi-tech-list {
  list-style: none;
  display: flex;
  margin-top: 16px;

  li {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    box-shadow: #00000080 0px 4px 16px;
    transition: transform 0.2s ease-in-out 0s;
    margin-right: 8px;

    &:hover {
      transform: translateY(-10px)
    }
  }
}

[data-tip] {
	position:relative;

}

[data-tip]:after {
  display: none;
  content: attr(data-tip);
  position: absolute;
  top: -35px;
  padding: 5px 8px;
  background: #1a1a1a;
  color: #fff;
  z-index: 14;
  font-size: 0.75em;
  height: 29px;
  line-height: 18px;
  border-radius: 3px;
  white-space: nowrap;
  word-wrap: normal;
}
[data-tip]:hover:before,
[data-tip]:hover:after {
	display:block;
}